export const LIONS_TOUR_2025_MENU = [
    {
        link: '/lions-series-2025',
        label: 'Series Information',
        permission: [
            "series_information",
            "key_contacts",
        ]
    },
    {
        link: '/lions-series-2025/key-contacts',
        label: 'Key Contacts',
        permission: [
            "key_contacts",
        ]
    },
];
