import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import Button from "~/Components/Button";
import PreviewFile from "~/Components/PreviewFile";
import Folders from "~/Components/Folders";
import Grid from "../Grid";
import List from "../List";
import Pagination from "react-js-pagination";
import './index.scss';
import Filter from "~/Components/Filter";
import {setOrderBy, setPerPage, setRefInfoElement, setView} from "~/Media/actions";
import Info from "./Info";
import LibraryApi from "~/services/api/library";
import moment from "moment";
import PermissionChecker from "~/Permissions/PermissionChecker";
import {connect} from "react-redux";
import {canManageMedia} from "~/MediaLibrary/permissions";

const GridListTemplate = (props) => {
    const [selectedDownloadItems, setSelectedDownloadItems] = useState(null);
    const {
        user,
        manageAssets,
        breadcrumbs,
        location,
        previewFile,
        showPreview,
        setState,
        renderBreadCrumbs,
        match,
        view,
        files,
        meta,
        folders,
        goToPage,
        setPreviewFile,
        parent,
        setSearchName,
        libraryScope,
        setLoaded,
        showInfo,
    } = props;

    let checker = new PermissionChecker(user);

    const deselectFiles = () => {
        setSelectedDownloadItems(null);
    }

    const selectAllItems = (setter) => {
        files.forEach(file => {
            setSelectedDownloadItems(prevSelected => ({...prevSelected, [file.id]:  file}))
        })
    }

    const downloadAll = () => {
        LibraryApi.downloadZip(files, setLoaded, 'path', '/api/uploads/zip/download?');
    }

    const downloadSelected = () => {
        if (selectedDownloadItems) {
            let selectedFiles = [];

            Object.keys(selectedDownloadItems).forEach(key => {
                selectedFiles.push(selectedDownloadItems[key]);
            });

            LibraryApi.downloadZip(selectedFiles, setLoaded, 'path', '/api/uploads/zip/download?');
        }
    }

    const getMonths = (updatedAt) => {
        let months = Number(moment().diff(moment(updatedAt), 'months', false));

        if (months === 1) {
            return months + ' month ago';
        } else if(months > 1) {
            return months + ' months ago';
        }

        let days = Number(moment().diff(moment(updatedAt), 'days', false));

        if (days === 1) {
            return days + ' day ago';
        } else if(days > 1) {
            return days + ' days ago';
        }

        return moment(updatedAt).format('DD/MM/YYYY');
    }

    return (
        <>
            <div className="grid-list-container">
                <Row className="justify-content-between">{user && checker.some(canManageMedia(parent.slug)) &&
                    <Col md={12} lg={12}>
                        <Button 
                            class="noFill no-radius download" 
                            text={(location.pathname !== '/brand/category/5') ? "Manage Assets" : "Manage Documents"} 
                            onClick={manageAssets}
                        />
                    </Col>
                }
                </Row>
                <Row className="justify-content-between">
                    {breadcrumbs && location.pathname !== '/media' &&
                    <Col md={12} lg={12} className="library__breadcrumbs d-flex"> {
                        breadcrumbs.map((item, index) => {
                            return renderBreadCrumbs(item, index);
                        })
                    }
                    </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <div className="library-name" title={parent.name}>
                            {parent.name.length > 20 ? parent.name.substring(0, 20) + '...' : parent.name}
                        </div>
                    </Col>
                    <Col>
                        <div className="justify-content-end d-flex">
                            {location.pathname !== '/media' &&
                            <Filter
                                perPage={props.perPage}
                                showGridToggle={location.pathname.indexOf('manage') < 0}
                                view={props.view}
                                orderBy={props.orderBy}
                                {...{setOrderBy, setPerPage, setView, setSearchName}}
                            />
                            }
                        </div>
                    </Col>
                </Row>
                <Info getMonths={getMonths} setLoaded={setLoaded}/>

                <PreviewFile
                    previewFile={previewFile}
                    showPreview={showPreview}
                    setLoaded={setLoaded}
                    setShowPreview={() => setState({showPreview: false})}
                />

                {folders && folders.length &&
                    <Folders folders={folders} uri={`/${libraryScope}/category/${match.params.id}/`} colClass={showInfo ? 'tiles-info col-xl-3 col-md-4 col-sm-6' : 'tiles'}/>
                }
                <Row className="ptop-75">
                    <Col className="grid-title">
                        <div className="list-header">ALL FILES</div>
                    </Col>
                    <Col> {files && files.length > 0 &&
                        <div className="list-header float-right">
                            <Button text="DOWNLOAD ALL" class="noFill download noMarg font-size-14 no-radius mb-0" onClick={downloadAll}/>
                        </div>
                    }
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col className={view === 'list' ? 'items list' : 'items'}>
                        {
                            view === 'grid'
                                ?
                                <Grid
                                    setPreviewFile={setPreviewFile}
                                    files={files}
                                    setSelectedDownloadItems={setSelectedDownloadItems}
                                    selectedDownloadItem={selectedDownloadItems}
                                    selectAllItems={selectAllItems}
                                    setLoaded={setLoaded}
                                    colClass={showInfo ? 'tiles-info col-xl-3 col-md-4 col-sm-6' : 'tiles'}
                                />
                                :
                                <List
                                    setPreviewFile={setPreviewFile}
                                    files={files}
                                    setSelectedDownloadItems={setSelectedDownloadItems}
                                    selectedDownloadItem={selectedDownloadItems}
                                    selectAllItems={selectAllItems}
                                    deselectFiles={deselectFiles}
                                    getMonths={getMonths}
                                    setLoaded={setLoaded}
                                    showInfo={showInfo}
                                />
                        }
                    </Col>
                </Row>

                <div className={selectedDownloadItems && Object.keys(selectedDownloadItems).length > 0  ? 'pagination-wrapper' : ''}>
                    {meta.per_page < meta.total && (
                        <Pagination
                            prevPageText={
                                <div className="prev-next">
                                    <img src="/images/icons/SVG/16/Chevron/Left.svg" alt="left"/>
                                </div>}
                            nextPageText={
                                <div className="prev-next">
                                    <img src="/images/icons/SVG/16/Chevron/Right.svg" alt="right"/>
                                </div>}
                            activePage={meta.current_page}
                            itemsCountPerPage={Number(meta.per_page)}
                            totalItemsCount={meta.total}
                            pageRangeDisplayed={5}
                            hideFirstLastPages={true}
                            itemClass="page-item"
                            linkClass="page-link"
                            linkClassPrev="prev-next"
                            linkClassNext="prev-next"
                            onChange={goToPage}
                        />
                    )}
                </div>
                </div>

            {selectedDownloadItems && Object.keys(selectedDownloadItems).length > 0 &&
                <div className="selected-footer">
                    <div className="d-flex">
                        <div className="footer-icon">
                            <img src="/images/icons/SVG/lions/checkbox-indeterminate.svg"/>
                        </div>
                        <div className="footer-labels">
                            {Object.keys(selectedDownloadItems).length} FILES SELECTED
                        </div>
                    </div>
                    <div className="footer-labels actions-label">
                        QUICK ACTIONS
                    </div>
                    <div className="d-flex select-deselect">
                        <div><Button class="btn-grey footer-downloads" text="DESELECT FILES" onClick={deselectFiles}/></div>
                        <div><Button class="btn-grey footer-downloads" text="SELECT ALL" onClick={selectAllItems}/></div>
                    </div>
                    <div className="d-flex">
                        <div><Button class="btn-grey footer-downloads" text="DOWNLOAD ALL" onClick={downloadAll}/></div>
                        <div><Button class="color-red font-size-14" text="DOWNLOAD SELECTED" onClick={downloadSelected}/></div>
                    </div>
                </div>
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    showInfo: state.media.showInfo,
});

export default connect(mapStateToProps)(GridListTemplate);
