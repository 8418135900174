import React from 'react';
import { connect } from 'react-redux';
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    Paper,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { PageMenu, PARTNER_INFORMATION_MENU } from '../Components/PageMenu';
import LibraryApi from "~/services/api/library";

const TicketsAndHospitality = (props) => {

    const useStyles = makeStyles((theme) => ({
        section: {
        },
        gridPadding: {
            paddingTop: 60,
            paddingBottom: 60
        },
        textPadding: {
            paddingTop: 20
        },        
        sectionTitle: {
            color: '#af001e', 
            textTransform: 'uppercase', 
            fontSize: 20, 
            fontWeight: 600,
            paddingBottom: '1em'
        },              
        table: {
            borderRadius: 0
        },       
        ul: {
            listStyle: 'none',
        },
        li: {
            '&:before': {
                content: "bob",
                color: 'red',
                fontWeight: 'bold',
                display: 'inline-block',
                width: '1em',
                marginLeft: '-1em',
            },
        },
        /*
        documentsBackground: {
            backgroundColor: 'white'
        },
        icons: {
            width: 22,
            height: 22
        },
        documentPaper: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            height: 46,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            columnGap: '5px',
        },
        downloadIcon: {
            padding: 10,
            marginTop: 5,
            marginBottom: 5,
            borderRadius: 0,
            textAlign: 'center'
        },
        downloadAll: {
            height: 50,
            boxShadow: '0 1px 4px 0 rgba(62, 63, 96, 0.25)',
            backgroundColor: '#af001e',
            borderRadius: 0,
            fontWeight: 600,
            marginTop: 5,
            color: 'white',
            "&:hover": {
                backgroundColor: '#af001eA5',
            }
        },        
        */
    }));

    const classes = useStyles();
    
    const createData = (id, date, match, stadion, platinum, gold, silver, bronze, high, low) => {
        return { id, date, match, stadion, platinum, gold, silver, bronze, high, low };
    }

    const rows = [
        createData(1, '20 June', 'British & Irish Lions v Argentina', 'Aviva Stadium, Dublin', 'EUR 150', 'EUR 130', 'EUR 110', 'EUR 65-95', 'EUR 2000', 'EUR 800'),
        createData(2, '28 June', 'Western Force v British & Irish Lions', 'Optus, Perth', 'AUD 249', 'AUD 159', 'AUD 109', 'AUD 59', 'AUD 2000', 'AUD 1000'),
        createData(3, '02 July', 'Queensland Reds v British & Irish Lions', 'Suncorp, Brisbane', 'AUD 249', 'AUD 159', 'AUD 109', 'AUD 59', 'AUD 1800', 'AUD 800'),
        createData(4, '05 July', 'NSW Waratahs v British & Irish Lions', 'Allianz, Sydney', 'AUD 249', 'AUD 189', 'AUD 129', 'AUD 89', 'AUD 2000', 'AUD 1000'),
        createData(5, '09 July', 'ACT Brumbies v British & Irish Lions', 'Gio, Canberra', 'AUD 249', 'AUD 159', 'AUD 109', 'AUD 59', 'AUD 1800', 'AUD 800'),
        createData(6, '12 July', 'AU & NZ v British & Irish Lions', 'Oval, Adelaide', 'AUD 269', 'AUD 199', 'AUD 129', 'AUD 99', 'AUD 2500', 'AUD 1200'),
        createData(7, '19 July', 'Test 1: Wallabies v British & Irish Lions', 'Suncorp, Brisbane', 'AUD 649', 'AUD 479', 'AUD 359', 'AUD 199', 'AUD 2800', 'AUD 2000'),
        createData(8, '22 July', 'TBC v British & Irish Lions', 'Marvel, Melbourne', 'AUD 249', 'AUD 189', 'AUD 129', 'AUD 89', 'AUD 1800', 'AUD 800'),
    ];    
    
    return (
        <section className={classes.section}>
            <Grid container direction="column">
                <Grid item>
                    <h1 className="content-title">Partner Information</h1>
                </Grid>
            </Grid>               
            <PageMenu menuItems={PARTNER_INFORMATION_MENU}/>
            <Divider />

            <Grid className={classes.gridPadding}>
                <Grid container>
                    <Typography variant="body1" className={classes.sectionTitle}>
                        Ticket & Hospitality Pricing
                    </Typography>            
                    <TableContainer component={Paper} className={classes.table}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ borderBottom: '4px solid white', textTransform: 'uppercase' }}>
                                    <TableCell colSpan="3" align="center" style={{ color: 'white', backgroundColor: '#af001e', textTransform: 'capitalize', fontSize: 14, fontWeight: 'bolder', borderRight: '2px solid white', padding: '10px' }}>Fixtures</TableCell>
                                    <TableCell colSpan="4" align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, fontWeight: 'bolder', borderRight: '2px solid white', padding: '10px' }}>Ticket Price</TableCell>
                                    <TableCell colSpan="2" align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, fontWeight: 'bolder', padding: '10px' }}>Hospitality Price</TableCell>
                                </TableRow>
                                <TableRow style={{ borderBottom: '2px solid white', textTransform: 'uppercase' }}>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: '#af001e', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>Date</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: '#af001e', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>Match</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: '#af001e', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>Venue</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>Platinum</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>Gold</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>Silver</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>Bronze</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, borderRight: '2px solid white', padding: '10px' }}>High</TableCell>
                                    <TableCell align="center" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', textTransform: 'capitalize', fontSize: 14, padding: '10px' }}>Low</TableCell>
                                </TableRow>
                                <TableRow style={{ borderBottom: '2px solid white', textTransform: 'uppercase' }}>
                                    <TableCell colSpan="9" align="left" style={{ color: '#000000', backgroundColor: 'rgba(0, 0, 0, 0.3)', textTransform: 'uppercase', fontSize: 14, fontWeight: 'bolder', padding: '5px' }}>PRE-TOUR / WARM-UP FIXTURES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell align="left">{row.match}</TableCell>
                                        <TableCell align="left">{row.stadion}</TableCell>
                                        <TableCell align="left">{row.platinum}</TableCell>
                                        <TableCell align="left">{row.gold}</TableCell>
                                        <TableCell align="left">{row.silver}</TableCell>
                                        <TableCell align="left">{row.bronze}</TableCell>
                                        <TableCell align="left">{row.high}</TableCell>
                                        <TableCell align="left">{row.low}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container className={classes.gridPadding}>
                    <Grid item md={8}>
                        <Typography variant="h5" className={classes.sectionTitle}>
                            Information
                        </Typography>
                        <Typography variant="body1">
                            Further information will be added at a later date and communicated via your 
                            Partner Manager, including stadium seating maps and lost or stolen ticket 
                            processes.              
                        </Typography>
                        <Typography variant="body1" className={classes.textPadding}>                            
                            Further information regarding hospitality will be added at a later date and 
                            communicated via your Partner Manager, including stadium maps, hospitality 
                            pricing, key deadlines, branding and food and beverage.
                        </Typography>
                        <Typography variant="body1" className={classes.textPadding}>                           
                            All Partners must notify LTAU of it’s ticket requirements by 1 January 2025.
                        </Typography>

                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.sectionTitle}>
                            Terms & Conditions
                        </Typography>
                        <Typography variant="body1">
                            Full ticket terms and conditions, as well as ticket resale terms and conditions will 
                            be available to download in due course.
                        </Typography>

                        {/*TODO - UNCOMMENT IF YOU WANT TO SHOW DOWNLOAD OPTIONS

                        <Grid container alignContent="center" spacing={1}>
                            <Grid item xs>
                                <Paper className={classes.documentPaper}>
                                    <img className={classes.icons} src="/images/icons/SVG/File Type/PDF.svg" /> Ticketing T&Cs.pdf
                                </Paper>
                            </Grid>
                            <Grid item>
                                <a href="/files/terms-and-conditions.pdf" download>
                                    <Paper className={classes.downloadIcon} >
                                        <img className={classes.icons} src="/images/icons/SVG/Download.svg" />
                                    </Paper>
                                </a>
                            </Grid>
                        </Grid>
                        <Grid container alignContent="center" spacing={1}>
                            <Grid item xs>
                                <Paper className={classes.documentPaper}>
                                    <img className={classes.icons} src="/images/icons/SVG/File Type/PDF.svg" /> Resale T&Cs.pdf
                                </Paper>
                            </Grid>
                            <Grid item>
                                <a href="/files/resale-policy.pdf" download>
                                    <Paper className={classes.downloadIcon}>
                                        <img className={classes.icons} src="/images/icons/SVG/Download.svg" />
                                    </Paper>
                                </a>
                            </Grid>
                        </Grid>
                        <Grid container alignContent="center" spacing={1}>
                            <Grid item xs>
                                <Paper className={classes.documentPaper}>
                                    <img className={classes.icons} src="/images/icons/SVG/File Type/PDF.svg" /> Terms of Use T&Cs.pdf
                                </Paper>
                            </Grid>
                            <Grid item>
                                <a href="/files/terms-of-use.pdf" download>
                                    <Paper className={classes.downloadIcon}>
                                        <img className={classes.icons} src="/images/icons/SVG/Download.svg" />
                                    </Paper>
                                </a>
                            </Grid>
                        </Grid>
                        <Button fullWidth className={classes.downloadAll} onClick={() => {
                            LibraryApi.downloadImage({ path: `/files/documents.zip`, name: 'documents', ext: 'zip' });
                        }}>Download All</Button>

                    */}
                    </Grid>

                </Grid>            
            </Grid>
        </section>
    );
}

const mapStateToProps = (state) => ({
    users: state.admin.users.rows,
});

export default connect(mapStateToProps)(TicketsAndHospitality);
