import React, {useEffect, useState} from 'react';
import Commands from "./Commands";
import ExtensionIcon from "~/Components/ExtensionIcon";
import ImagePreviewIcon from "~/Components/Icons/ImagePreviewIcon";

const GridItem = ({setPreviewFile, item, setSelectedDownloadItems,selectedDownloadItem, setLoaded}) => {
    const[showCommands, setShowCommands] = useState({});

    useEffect(() => {
        if (!selectedDownloadItem) {
            setShowCommands({});
        }
        if (selectedDownloadItem && selectedDownloadItem[item.id]) {
            setShowCommands({id: item.id});
        }
    }, [selectedDownloadItem])

    return (
        <div>
            <div className="library"
                 onMouseEnter={() => setShowCommands({id: item.id})}
                 onMouseLeave={() => {
                     if (!selectedDownloadItem || selectedDownloadItem && !selectedDownloadItem[item.id]) {
                         setShowCommands({});
                     }}}
            >
                <div className="library__thumbnail">
                    <div className={`${selectedDownloadItem && selectedDownloadItem[item.id] ? 'active-border' : 'transparent-border'}`}>
                        {item.media_type === 'videos' || item.media_type === 'photos'
                            ?
                            <img src={item.thumbnail_image} className="library__thumbnail__images"
                                 onClick={() => { setPreviewFile(item) }}
                            />
                            :
                            <div className="library__thumbnail dark-bg no-cursor" onClick={() => { setPreviewFile(item) }}>
                                <ExtensionIcon text={item.ext} width="77%" height="77%" />
                            </div>
                        }
                        <div className="image-info" onClick={() => {
                            setPreviewFile(item)
                        }} style={item.media_type ? {cursor: 'pointer'} : {cursor: 'default'}}>
                            <div className="icon-container"><ImagePreviewIcon/></div>
                            <div className="image-name-info"
                                 title={item.name}>{item.name.length > 22 ? item.name.substring(0, 22) + '...' : item.name}</div>
                        </div>

                        {(showCommands.id === item.id || (selectedDownloadItem && selectedDownloadItem[item.id])) &&
                        <Commands
                            file={item}
                            setLoaded={setLoaded}
                            selectedDownloadItem={selectedDownloadItem}
                            setSelectedDownloadItems={setSelectedDownloadItems}
                            setShowCommands={setShowCommands}
                        />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridItem;
