import React, {useEffect, useState} from 'react';
import './index.scss'
import CloseIcon from "~/Components/Icons/CloseIcon";
import {Row, Col} from 'react-bootstrap'
import ImagePreviewIcon from "~/Components/Icons/ImagePreviewIcon";
import DownloadIcon from "~/Components/Icons/DownloadIcon";
import LibraryApi from "~/services/api/library";
import {connect} from "react-redux";
import {setRefInfoElement, setShowInfo} from "~/Media/actions";

const Info = ({showInfo, getMonths, setLoaded, refInfoElement, dispatch}) => {

    const [item, setItem] = useState(null);
    useEffect(() => {
        if (showInfo) {
            setItem(null);
            axios.get('/api/uploads/' + showInfo).then((resp) => {
                setItem(resp.data.data);
            }).catch((error) => {
                console.log(error.response);
            })
        }
    }, [showInfo]);



    const renderSiblings = () => {
        if (item.siblings) {

            return item.siblings.map((sibling, key) => {
               return (
                   <div key={key}>
                       <Row className="image-row-wrapper m-0">
                           <Col md={1} className="pl-0"><ImagePreviewIcon/></Col>
                           <Col md={9} className="image-download-name text-elipsis" title={sibling.name}>
                               {sibling.name}.{sibling.ext}
                           </Col>
                           <Col md={2} className="pl-0"><DownloadIcon file={sibling} setLoaded={setLoaded}/></Col>
                       </Row>
                   </div>
               )
            });
        }
    }

    const downloadAll = () => {

        if (item.siblings) {
            LibraryApi.downloadZip(item.siblings, setLoaded, 'path', '/api/uploads/zip/download?');
        }
    }

    const closeInfo = () => {
        dispatch(setShowInfo(false));
        document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    }

    const setReference = (r) => {
        if (r !== refInfoElement) {
            dispatch(setRefInfoElement(r))
        }
    }

    return (
        <div className={showInfo ? 'info-sidebar active' : 'info-sidebar'} ref={setReference}>{item &&
            <>
                <Row>
                    <Col className="text-right">
                        <CloseIcon setShowInfo={() => closeInfo()}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="image-name" title={item.name}>
                            {item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name}
                        </div>
                    </Col>
                </Row>
                {item.media_type === 'videos' || item.media_type === 'photos' &&
                <Row>
                    <Col>
                        <div className="image-wrapper">
                            <img src={item.converted_file_path || item.path} alt={item.name}/>
                        </div>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <Row className="details-wrapper">
                            <Col md={3}>SIZE</Col>
                            <Col className="detail-values">{item.size > 0 ? (item.size / 1000) : 0 } MB</Col>
                        </Row>
                        <Row className="details-wrapper">
                            <Col md={3}>FORMAT</Col>
                            <Col className="detail-values text-uppercase">{item.ext}</Col>
                        </Row>
                        <Row className="details-wrapper">
                            <Col md={3}>MODIFIED</Col>
                            <Col className="detail-values">
                                {getMonths(item.updated_at)}
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row className="red-border"/>
                <Row className="files-title">
                    <Col>FILE VERSION</Col>
                </Row>
                {renderSiblings()}
                <Row className="mtop-60 mbottom-30">
                <span className="button fullWidth color-red info-download" onClick={downloadAll}>
                    <span className="download-icon"><img src="/images/icons/SVG/download_list.svg" alt="download"/></span>
                    <span>DOWNLOAD</span>
                </span>
                </Row>

            </>
        }

        </div>
    );
};

const mapStateToProps = (state) => ({
    showInfo: state.media.showInfo,
    refInfoElement: state.media.refInfoElement,
})

export default connect(mapStateToProps)(Info);
