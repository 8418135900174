export const PARTNER_INFORMATION_MENU = [
    {
        link: '/partner-information',
        label: 'Partner Handbook',
        permission: [
            "view_ticket_hospitality",
            "view_appearances",
            "view_activation",
            "view_partner_workshops",
            "view_right_protection",
            "view_documents",
        ]
    },
    {
        link: '/partner-information/partner-workshops',
        label: 'Partner Workshops',
        permission: [
            "view_partner_workshops",
        ]
    },
    {
        link: '/partner-information/tickets-and-hospitality',
        label: 'Tickets & Hospitality',
        permission: [
            "view_ticket_hospitality",
        ]
    },
    {
        link: '/partner-information/appearances',
        label: 'Appearances',
        permission: [
            "view_appearances",           
        ]
    },
    {
        link: '/partner-information/activation',
        label: 'Activation',
        permission: [
            "view_activation",           
        ]
    },
    {
        link: '/partner-information/rights-protection',
        label: 'Rights Protection',
        permission: [
            "view_right_protection",
        ]
    },
];
