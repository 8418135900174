import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HeaderInnerNavigation from "~/Components/HeaderInnerNavigation";
import ProtectedRoute from "~/ProtectedRoute";
import Item from "~/MediaLibrary/Item";
import { Switch } from "react-router-dom";
import ManageItem from "~/MediaLibrary/Manage";
import { connect } from "react-redux";
import './index.scss';
import Grid from "@material-ui/core/Grid";
import AdminPartnersDropdown from "~/Components/AdminPartnersDropdown/AdminPartnersDropdown";

const Brand = (props) => {

    const [brandLibraries, setBrandLibraries] = useState(null);
    const [navigationCurrent, setNavigationCurrent] = useState(null);
    useEffect(() => {
        axios.get('/api/media-categories/brand-libraries').then((response) => {
            let libraries = response.data.data
            if (libraries.length > 0) {
                setBrandLibraries(libraries);
            }

        });
    }, []);

    useEffect(() => {
        if (props.location.pathname === '/brand' && brandLibraries) {
            props.history.push('/brand/category/' + brandLibraries[0].id);
        }
        let regex = new RegExp(/^\/brand\/category|manage\//)
        if (!regex.test(props.location.pathname) && props.location.pathname !== '/brand') {
            props.history.push('/404');
        }

    }, [props.location.pathname, brandLibraries]);

    return (
        <div className={props.showInfo && props.refInfoElement ? 'media-wrapper resized' : 'media-wrapper'} style={{
            width: props.showInfo && props.refInfoElement ? `calc(100% - ${props.refInfoElement.offsetWidth}px)` : '100%',
        }}>
            <section className="bodyContent media-library bottom-line">
                <section className="library-header">
                    <Grid container direction="row" alignItems="center" justify="space-between">
                        <Grid item>
                            <h1 className="content-title">Brand & Documents</h1>
                        </Grid>
                        <Grid item>
                            <AdminPartnersDropdown tabId="1"/>
                        </Grid>
                    </Grid>
                    <div>

                        {brandLibraries &&
                            <HeaderInnerNavigation items={brandLibraries} basePath="/brand/category"
                                navigationCurrent={navigationCurrent} />
                        }
                    </div>
                </section>

            </section>
            <section className="library-content">

                <Switch>
                    <ProtectedRoute exact path={`${props.match.path}/category/:id`}
                        setNavigationCurrent={setNavigationCurrent}
                        libraryScope="brand"
                        component={Item} />
                    <ProtectedRoute exact path={`${props.match.path}/category/:id/:folderId`}
                        libraryScope="brand"
                        setNavigationCurrent={setNavigationCurrent}
                        component={Item} />
                    <ProtectedRoute exact path={`${props.match.path}/manage/:id/:folderId?`}
                        setNavigationCurrent={setNavigationCurrent}
                        component={ManageItem} />
                </Switch>
            </section>
        </div>
    );
};
const mapStateToProps = (state) => ({
    showInfo: state.media.showInfo,
    refInfoElement: state.media.refInfoElement,
});
export default connect(mapStateToProps)(Brand);
